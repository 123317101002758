import PropTypes from "prop-types";
import React from "react";
import Container from "react-bootstrap/Container";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Content from "../content/produk/transparent-indoor-led/P3.91/Product-Page.json";
import "../styles/product.css";

const TIL = ({ siteTitle }) => {
    return (
        <Layout>
            <SEO
                title={Content.seo.title}
                description={Content.seo.description}
                lang={Content.seo.lang}
            />
            <section id={"produk"} className={"section-bg"}>
                <Container>
                    <div className={"section-title"}>
                        <h1>Transparent Indoor LED Display Module</h1>
                    </div>
                </Container>
            </section>
        </Layout>
    );
};

TIL.propTypes = {
    siteTitle: PropTypes.string,
};

TIL.defaultProps = {
    siteTitle: ``,
};

export default TIL;
